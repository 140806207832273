import {Container, Heading} from '@chakra-ui/react'
import React from 'react'
import responsiveFontSize from '../@chakra-ui/gatsby-plugin/responsiveFontSize'
import {BlopLink} from '../components/UI/atoms'

const NotFoundPage = () => <Container minH={'100vh'} display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
	<Heading as={'h1'} fontSize={responsiveFontSize(100)} lineHeight={'1em'} textAlign={'center'}>Oops, something went wrong!</Heading>
	<BlopLink
		fontSize={responsiveFontSize(20)}
		lineHeight={responsiveFontSize(20)}
		href={'/'} mt={responsiveFontSize(42)}
		isActive
	>Return to home</BlopLink>
</Container>

export default NotFoundPage
